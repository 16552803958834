import * as React from "react"
import {Link, graphql} from 'gatsby'
import Helmet from "react-helmet"

// markup
const IndexPage = ({data}) => {
    let page = data.wpPublicpage.publichomepage;
    let cards = page.injuryCards;

    return (
        <>
            <Helmet>
                <title>Careway</title>
                <meta name="description" content="Careway is an ACC funded programme that provides injured New Zealanders with better and faster access to great healthcare services. You will receive the care you need, when you need it, at no cost to yourself." />
            </Helmet>

            
            <div id="hero-homepage" className="hero" style={{backgroundImage: "url('/img/Hero-Image-4-BBall-A.jpg')", backgroundPositionY: '30%' }}>
                <div className="hero-container">
                    <div className="hero-div">
                        <h1>{page.headline}</h1>
                        <p>{page.subtitle}</p>
                    </div>
                </div>
            </div>



            <div className="section link-blurb-section">
                <div className="section-inner">
                    <div className="link-blurb-wrap">
                        <div className="link-blurb">
                            <img src="/img/Icon-Resources.svg" className="link-blurb-img" alt="Resources Icon" />
                            <div className="link-blurb-info">
                                <Link to="/about" className="link-blurb-title">About Careway <span className="icon arrow-right"></span></Link>
                            </div>
                        </div>
                    </div>
                    <div className="link-blurb-wrap">
                        <div className="link-blurb">
                            <img src="/img/Icon-Consent-Form.svg" className="link-blurb-img" alt="Consent Form Icon" />
                            <div className="link-blurb-info">
                                <a href="/pdf/Careway-Consent-Form.pdf" target="_blank" className="link-blurb-title">Consent Form <span className="icon arrow-right"></span></a>
                            </div>
                        </div>
                    </div>
                    <div className="link-blurb-wrap">
                        <div className="link-blurb">
                            <img src="/img/Icon-Find-Provider.svg" className="link-blurb-img" alt="Find Providers Icon" />
                            <div className="link-blurb-info">
                                <Link to="/providers" className="link-blurb-title">Find A Provider <span className="icon arrow-right"></span></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <div className="section intro-section">
                <div className="section-inner">
                    <div className="fullwidth-blurb" >
                        <div dangerouslySetInnerHTML={{ __html: page.subHeader }}  />
                    </div>
                </div>
            </div>


            <div className="section" id="homepage-content">
                <div className="section-inner info-blurbs">
                    {cards.map(element => {
                        return(
                            <div className="info-blurb">
                                <img src={element.icon.sourceUrl} alt={element.icon.altText} />
                                <h3>{element.title}</h3>
                                <p>{element.description}</p>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )
}


export const query = graphql`
    query MyQuery {
        wpPublicpage(id: {eq: "cG9zdDoxNTc4"}) {
            publichomepage {
                headline
                subHeader
                subtitle
                injuryCards {
                    title
                    description
                    icon {
                        altText
                        sourceUrl
                    }
                }
            }
        }
    }
`;

export default IndexPage
